import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    // 会匹配所有路径
    path: "*",
    name: "404notFound",
    component: () => import("../views/404/404notfound.vue"),
  },
  {
    path: "/",
    name: "/",
    redirect: "/admin",
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/layout.vue"),
    // meta: { title: "首页" },
    children: [
      {
        path: "/index",
        name: "index",
        // component: Home,
        component: () => import("../views/Home.vue"),
        meta: { title: "首页" },
      },
      {
        path: "/businessManagement",
        name: "businessManagement",
        component: () => import("../views/business-management.vue"),
        meta: { title: "客户管理" },
      },
      {
        path:"/businessexamine",
        name:"businessexamine",
        component: () => import("../views/business-examine.vue"),
        meta: { title: "审核管理" },
      },
      {
        path:"/businessreject",
        name:"businessreject",
        component: () => import("../views/business-reject.vue"),
        meta: { title: "驳回列表" },
      },
      {
        path: "/renewalManagement",
        name: "renewalManagement",
        component: () => import("../views/renewal-management.vue"),
        meta: { title: "续费管理" },
      },
      {
        path: "/taskrelease",
        name: "taskrelease",
        component: () => import("../views/task-release.vue"),
        meta: { title: "任务发布" },
      },
      {
        path: "/mymission",
        name: "mymission",
        component: () => import("../views/my-mission.vue"),
        meta: { title: "我的任务" },
      },
      {
        path: "/employeemanagement",
        name: "employeemanagement",
        component: () => import("../views/employee-management.vue"),
        meta: { title: "员工管理" },
      },
      {
        path: "/rolemanagement",
        name: "rolemanagement",
        component: () => import("../views/rolemanagement.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "/menumanagement",
        name: "menumanagement",
        component: () => import("../views/menu-management.vue"),
        meta: { title: "菜单管理" },
      },
      {
        path: "/fundmanagement/finaccialmanagement",
        component: () =>
          import("../views/fundmanagement/financial-management.vue"),
        name: "用户详情",
        meta: { title: "财务管理" },
      },

      {
        path: "/workordermanagement/myworkorder",
        component: () => import("../views/workordermanagement/myworkorder.vue"),
        name: "我的工单",
        meta: { title: "我的工单" },
      },
      {
        path: "/workordermanagement/workorderreleaser",
        component: () =>
          import("../views/workordermanagement/workorder-release.vue"),
        name: "工单发布",
        meta: { title: "工单发布" },
      },
      {
        path: "/performance-statistics",
        name: "performancestatistics",
        component: () => import("../views/performance-statistics.vue"),
        meta: { title: "业绩统计" },
      },
      {
        path: "/area-management",
        name: "areamanagement",
        component: () => import("../views/area-management.vue"),
        meta: { title: "片区管理" },
      },
      // {
      //   path: '/about',
      //   name: 'About',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      // }
    ],
    redirect: "/index",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/admin") return next();
  //获取token
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/admin");
  next();
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
