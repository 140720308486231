<template>
  <div class="hander-Container">
    <div class="hander-nav">
      <div class="hander-title">天灿会计服务后台系统</div>
      <div class="hander-mianbao">
        <span>当前位置：</span>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(item, index) in levelList"
            :key="index"
            :to="item.path"
            >{{ item.meta.title }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <el-input
      placeholder="搜索..."
      suffix-icon="el-icon-search"
      v-model="queryInfo.global_search"
      style="width: 400px; border-radius: 30px"
      @keyup.enter.native="globalSearchCustomer($event)"
      class="searchInput"
    >
    </el-input>
    <div class="hander-seNav">
      <div class="hander-seNavItem" v-for="item in seNavItem" :key="item.id">
        <router-link to=""
          ><span class="iconfont" :class="item.iconfontclass"></span
          ><span>{{ item.name }}</span></router-link
        >
      </div>
      <div class="hander-seNav-ava">
        <el-popover placement="top" width="50" trigger="hover">
          <div class="hander-seNav-ava-FlexBox">
            <div class="seNav-avaItem" @click="PassWorddialogVisible = true">
              修改密码
            </div>
            <div class="seNav-avaItem">个人资料</div>
            <div class="seNav-avaItem" @click="Logout">退出登录</div>
          </div>
          <img src="../assets/img-ava.jpg" alt="" slot="reference" />
        </el-popover>
      </div>
    </div>
    <!-- 修改密码框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="PassWorddialogVisible"
      width="30%"
      :before-close="handleClose"
      @close="handleClose"
      custom-class="passwordDialog"
    >
      <el-form
        :model="editPassword"
        :rules="editPasswordRules"
        ref="eidtPasswordForm"
        label-width="80px"
      >
        <el-form-item label="原密码:" label-position="left">
          <el-input v-model="editPassword.primary_password"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" label-position="left">
          <el-input v-model="editPassword.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" label-position="left">
          <el-input v-model="editPassword.password_confirm"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitEditPassword">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "handerCom",
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.editPassword.password !== value) {
        callback(new Error("两次输入的密码不一致"))
      } else {
        callback()
      }
    };
    return {
      levelList: [],
      seNavItem: [
        { iconfontclass: 'icon-wodekehu', name: '我的客户', id: 1 },
        { iconfontclass: 'icon-tonggao', name: '通知公告', id: 2 },
        { iconfontclass: 'icon-tousujianyi', name: '投诉建议', id: 3 },
        { iconfontclass: 'icon-bangzhushouce', name: '帮助手册', id: 4 },
      ],
      queryInfo: {
        global_search: '',
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      searchlist: [],
      PassWorddialogVisible: false,//修改密码对话框
      editPassword: {
        primary_password: '',//原密码
        password: '',//新密码
        password_confirm: '',//确认密码
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      editPasswordRules: {
        password: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 12, message: "长度在8到12个字符", trigger: "blur" }
        ],
        password_confirm: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ]
      }
    }
  },
  watch: {
    $route(newVal) {
      console.log(newVal);
      this.getBreadcrumb()

    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    //根据路由对的matched属性来过滤
    getBreadcrumb() {
      // 把 matched数组中每一个对象的name值过滤出来返回一个新的数组
      let matched = this.$route.matched.filter(item => item.name)
      // const first = matched[1];
      // // trim去掉空白 toLocaleLowerCase用于把字符串转换为小写。
      // if (first && first.name.trim().toLocaleLowerCase() !== 'index'.toLocaleLowerCase()) {             
      //   matched = [{ path: '/index', meta: { title: '首页' } }].concat(matched)
      // }
      this.levelList = matched

    },
    async globalSearchCustomer(event) {
      event.target.blur();
      const res = await this.$http.get(
        "Customer/index",
        {
          params: this.queryInfo
        }
      );
      // console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.status !== 200) return this.$message.error("搜索失败!");
      else if (res.data.data.length === 0)
        return this.$message.error("没有相关联的客户信息!");
      this.searchlist = res.data.data;
      // this.$router.push('./businessManagement')
      this.$router.push({ name: 'businessManagement', query: this.searchlist });
      console.log(this.searchlist);
      this.queryInfo.global_search = ''
    },
    //提交修改密码请求
    async submitEditPassword() {
      const res = await this.$http.post('Admin/pass_edit', this.editPassword)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200)
        // return this.$message.error(res.data.msg)
        return this.$message({
          showClose: true,
          message: res.data.msg,
          type: 'error',
          offset: 10
        });
      this.$message({
        showClose: true,
        message: res.data.msg + '正在跳转登录页...',
        type: 'success',
        offset: 10,
        duration: 3000,
        onClose: () => {
          this.$router.push('./admin')
        }
      });

      this.PassWorddialogVisible = false
    },
    //修改密码对话框关闭
    handleClose() {
      this.PassWorddialogVisible = false
      this.$refs.eidtPasswordForm.resetFields()
    },
    async Logout() {
      const res = await this.$http.get('Login/signOut', { params: this.queryInfo })
      console.log(res);
      if (res.data.code !== 201)
        return this.$message.error(res.data.msg)
      this.$message.success(res.data.msg)
      window.sessionStorage.clear();
      this.$router.push('./admin')

    }
  }
}
</script>

<style lang="scss">
.el-popover {
  padding: 0;
  min-width: 100px !important;
}
.hander-seNav-ava-FlexBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  .seNav-avaItem {
    padding: 10px 0;
    font-size: 16px;
    text-align: center;
    width: 100px;
    color: $index-hui-color;
    cursor: pointer;
  }
  .seNav-avaItem:hover {
    color: $index-blue-color;
  }
}

.hander-Container {
  color: #abc4dc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-size: 20px;
}
.hander-title {
  width: 220px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.hander-nav {
  display: flex;
  align-items: center;
}
.hander-mianbao {
  display: flex;
  margin-left: 50px;
  align-items: center;
}
.el-breadcrumb__inner {
  cursor: pointer !important;
  color: #0d98c4 !important;
  font-size: 16px;
}
.hander-Container .searchInput {
  .el-input__inner {
    border-radius: 20px !important;
    font-size: 16px;
  }
}
.hander-seNav {
  display: flex;
  align-items: center;
  margin-right: 35px;
  .hander-seNavItem a {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
    color: #abc4dc !important;
  }
  .hander-seNavItem span:first-child {
    margin-right: 10px;
  }
}
.hander-seNav-ava img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
//密码框
.passwordDialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    height: 10px;
    line-height: 10px;
    text-align: left;
    padding: 20px;
    .el-dialog__title {
      color: #ffffff;
      font-size: 16px;
      // font-weight: bold;
      line-height: 0px;
    }
    .el-dialog__close {
      color: #ffffff;
    }
  }

  .el-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-form-item {
      .el-form-item__label {
        text-align: left;
      }
      .el-input {
        border-radius: 0;
        width: 350px;
      }
      .el-input__inner {
        height: 35px;
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding: 10px 20px 10px;
    .el-button--primary {
      background-color: #ff7079 !important;
      border-color: #ff7079 !important;
      height: 35px;
      line-height: 10px;
      width: 90px;
    }
  }
}
</style>