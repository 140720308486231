<template>
  <div class="menu-container">
    <div class="title-ava">
      <!-- 头像 -->
      <img src="../assets/img-ava.jpg" />
      <div class="title-UserName">
        <!-- 名字 -->
        {{ $store.state.adminlist.real_name
        }}<span>
          <!-- 角色名 -->
          ({{ $store.state.adminlist.role.name }})</span
        >
        <p style="font-size: 14px">({{ $store.state.adminlist.area_name }})</p>
      </div>
    </div>
    <div class="router-menu">
      <el-menu
        :default-active="defaultActiveIndex"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#333a4d"
        text-color="#fff"
        active-text-color="#02aadb"
        @select="handleselect"
        router
      >
        <!-- unique-opened -->
        <el-menu-item index="/index">
          <i class="iconfont icon-my-current-copy"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <!-- 当角色为外勤，外勤主管，人事主管，人事时不显示客户管理 -->
        <el-submenu
          index="2"
          v-if="
            this.$store.state.adminlist.role_id !== 3 &&
            this.$store.state.adminlist.role_id !== 8 &&
            this.$store.state.adminlist.role_id !== 13 &&
            this.$store.state.adminlist.role_id !== 14
          "
        >
          <template slot="title">
            <i class="iconfont icon-kehuguanli"></i>
            <span>客户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/businessManagement">
              <i class="iconfont icon-kehuguanli"></i>
              <span slot="title">客户管理</span>
            </el-menu-item>
            <el-menu-item
              index="/businessexamine"
              v-if="
                this.$store.state.adminlist.role_id == 1 ||
                this.$store.state.adminlist.role_id == 5 ||
                this.$store.state.adminlist.role_id == 6 ||
                this.$store.state.adminlist.role_id == 10 ||
                this.$store.state.adminlist.role_id == 15 ||
                this.$store.state.adminlist.role_id == 18
              "
            >
              <i class="iconfont icon-jiaoseguanli"></i
              ><span>审核管理</span></el-menu-item
            >
            <el-menu-item
              index="/businessreject"
              v-if="
                this.$store.state.adminlist.role_id == 1 ||
                this.$store.state.adminlist.role_id == 5 ||
                this.$store.state.adminlist.role_id == 6 ||
                this.$store.state.adminlist.role_id == 10 ||
                this.$store.state.adminlist.role_id == 15 ||
                this.$store.state.adminlist.role_id == 18
              "
            >
              <i class="iconfont icon-jiaoseguanli"></i
              ><span>驳回列表</span></el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-menu-item index="/renewalManagement">
          <i class="iconfont icon-xufeiguanli"></i>
          <span slot="title">续费管理</span>
        </el-menu-item>

        <el-submenu index="/taskrelease">
          <template slot="title">
            <i class="iconfont icon-daibanguanli1"></i>
            <span>任务管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/mymission">
              <i class="iconfont icon-daibanguanli1"></i>
              <span slot="title">我的任务</span>
            </el-menu-item>
            <el-menu-item index="/taskrelease">
              <i class="iconfont icon-daibanguanli1"></i>
              <span slot="title">任务发布</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
        <!-- <el-submenu index="/workordermanagement">
          <template slot="title">
            <i class="iconfont icon-gongdanguanli"></i>
            <span>工单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/workordermanagement/myworkorder">
              <i class="iconfont icon-daibanguanli1"></i>
              <span slot="title">我的工单</span>
            </el-menu-item>
            <el-menu-item index="/workordermanagement/workorderreleaser">
              <i class="iconfont icon-daibanguanli1"></i>
              <span slot="title">工单发布</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
        <!-- <el-submenu index="5">
          <template slot="title">
            <i class="iconfont icon-daibanguanli1"></i>
            <span>资金管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/fundmanagement/finaccialmanagement">
              <i class="iconfont icon-daibanguanli1"></i
              ><span>财务管理</span></el-menu-item
            >
            <el-menu-item index="5-2" route="/fundmanagement/finaccialmanagement">
              <i class="iconfont icon-daibanguanli1"></i
              ><span>添加财务</span></el-menu-item
            >
            <el-menu-item index="5-3" route="/fundmanagement/finaccialmanagement">
              <i class="iconfont icon-daibanguanli1"></i
              ><span>退款审批</span></el-menu-item
            >
          </el-menu-item-group>
        </el-submenu> -->
        <el-submenu
          index="7"
          v-if="
            this.$store.state.adminlist.role_id === 14 ||
            this.$store.state.adminlist.role_id === 13 ||
            this.$store.state.adminlist.role_id === 1
          "
        >
          <template slot="title">
            <i class="iconfont icon-renliziyuan"></i>
            <span>人员管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/employeemanagement">
              <i class="iconfont icon-yuangongguanli"></i>
              <span slot="title">员工管理</span>
            </el-menu-item>
            <!-- <el-menu-item index="/menumanagement">
              <i class="iconfont icon-daibanguanli1"></i
              ><span>菜单管理</span></el-menu-item
            > -->
            <el-menu-item index="/rolemanagement">
              <i class="iconfont icon-jiaoseguanli"></i
              ><span>角色管理</span></el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-menu-item index="/performance-statistics">
          <i class="iconfont icon-daibanguanli1"></i>
          <span slot="title">业绩统计</span>
        </el-menu-item> -->
        <!-- <el-submenu index="9">
          <template slot="title">
            <i class="iconfont icon-shape5"></i>
            <span>系统管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/area-management">
              <i class="iconfont icon-quyuguanli"></i>
              <span slot="title">片区管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  name: "mune",
  data() {
    return {
      defaultActiveIndex: '/index'
    }
  },
  methods: {
    handleOpen(index) {
      console.log(index)
    },
    handleClose(index) {
      console.log(index)
    },
    handleselect(index) {
      this.defaultActiveIndex = index;
      console.log(this.defaultActiveIndex)
      this.saveNavstate(this.defaultActiveIndex)
    },
    saveNavstate(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
    }
  },
  beforeDestroy() {

  },
  updated() {

  },
  watch: {
    $route(newval) {
      // console.log(newval);
      this.defaultActiveIndex = newval.path
    }
  },
  // 创建的钩子函数
  created() {
    this.defaultActiveIndex = window.sessionStorage.getItem('activePath')
  },

}
</script>

<style lang="scss">
.menu-container {
  height: 100%;
}
.title-ava {
  box-sizing: border-box;
  width: 100%;
  // height: 1px;
  padding: 20px;
  //align-items: center;
  img {
    margin: 0 auto;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
}
.title-UserName {
  color: #abc4dc;
  font-size: 20px;
  font-weight: bold;
  span {
    margin: 0 5px;
    font-size: 14px;
  }
}
.el-menu-vertical-demo {
  width: 100%;
  .el-menu-item-group__title {
    padding: 0;
  }
}
.el-menu-item,
.el-submenu,
.el-submenu__title {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}
.iconfont {
  margin-right: 20px;
}
.is-active {
  color: #02aadb !important;
  background-color: #222e3c !important;
}
.el-menu-item:focus,
.el-menu-item:hover {
  // color: #02aadb !important;
  background-color: #222e3c !important;
}
</style>