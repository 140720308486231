var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"title-ava"},[_c('img',{attrs:{"src":require("../assets/img-ava.jpg")}}),_c('div',{staticClass:"title-UserName"},[_vm._v(" "+_vm._s(_vm.$store.state.adminlist.real_name)),_c('span',[_vm._v(" ("+_vm._s(_vm.$store.state.adminlist.role.name)+")")]),_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("("+_vm._s(_vm.$store.state.adminlist.area_name)+")")])])]),_c('div',{staticClass:"router-menu"},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.defaultActiveIndex,"background-color":"#333a4d","text-color":"#fff","active-text-color":"#02aadb","router":""},on:{"open":_vm.handleOpen,"close":_vm.handleClose,"select":_vm.handleselect}},[_c('el-menu-item',{attrs:{"index":"/index"}},[_c('i',{staticClass:"iconfont icon-my-current-copy"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("首页")])]),(
          this.$store.state.adminlist.role_id !== 3 &&
          this.$store.state.adminlist.role_id !== 8 &&
          this.$store.state.adminlist.role_id !== 13 &&
          this.$store.state.adminlist.role_id !== 14
        )?_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont icon-kehuguanli"}),_c('span',[_vm._v("客户管理")])]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"/businessManagement"}},[_c('i',{staticClass:"iconfont icon-kehuguanli"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("客户管理")])]),(
              this.$store.state.adminlist.role_id == 1 ||
              this.$store.state.adminlist.role_id == 5 ||
              this.$store.state.adminlist.role_id == 6 ||
              this.$store.state.adminlist.role_id == 10 ||
              this.$store.state.adminlist.role_id == 15 ||
              this.$store.state.adminlist.role_id == 18
            )?_c('el-menu-item',{attrs:{"index":"/businessexamine"}},[_c('i',{staticClass:"iconfont icon-jiaoseguanli"}),_c('span',[_vm._v("审核管理")])]):_vm._e(),(
              this.$store.state.adminlist.role_id == 1 ||
              this.$store.state.adminlist.role_id == 5 ||
              this.$store.state.adminlist.role_id == 6 ||
              this.$store.state.adminlist.role_id == 10 ||
              this.$store.state.adminlist.role_id == 15 ||
              this.$store.state.adminlist.role_id == 18
            )?_c('el-menu-item',{attrs:{"index":"/businessreject"}},[_c('i',{staticClass:"iconfont icon-jiaoseguanli"}),_c('span',[_vm._v("驳回列表")])]):_vm._e()],1)],2):_vm._e(),(
          this.$store.state.adminlist.role_id === 14 ||
          this.$store.state.adminlist.role_id === 13 ||
          this.$store.state.adminlist.role_id === 1
        )?_c('el-submenu',{attrs:{"index":"7"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont icon-renliziyuan"}),_c('span',[_vm._v("人员管理")])]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"/employeemanagement"}},[_c('i',{staticClass:"iconfont icon-yuangongguanli"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("员工管理")])]),_c('el-menu-item',{attrs:{"index":"/rolemanagement"}},[_c('i',{staticClass:"iconfont icon-jiaoseguanli"}),_c('span',[_vm._v("角色管理")])])],1)],2):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }