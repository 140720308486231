import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入Element-UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

import axios from "axios";
axios.defaults.baseURL ="http://admin.gxtc2018.com/admin"; // http://admin.gxtc2018.com/admin   http://tc.qianji.org.cn/admin
//http://admin.gxtc2018.com/admin

Vue.prototype.$http = axios;
axios.defaults.withCredentials = true;

//转换时间格式
import moment from "moment/moment";
Vue.filter("moment", function(value, formatString) {
	formatString = formatString || "YYYY-MM-DD";
	return moment(value).format(formatString);
});

Vue.filter("dateFormat", function(originVal) {
	const dt = new Date(originVal);
	const y = dt.getFullYear();
	const m = (dt.getMonth() + 1 + "").padStart(2, "0");
	const d = (dt.getDate() + "").padStart(2, "0");

	return `${y}-${m}-${d}`;
});



Vue.use(VueQuillEditor);
Vue.config.productionTip = false;

Vue.use(ElementUI);
import handerCom from "./components/handerCom";
import muneCom from "./components/mune.vue";

Vue.component("muneCom", muneCom);
Vue.component("handerCom", handerCom);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
