import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    login: false,
    adminlist: [],
    // 修改套餐 state
    set_meal: {},
  },
  mutations: {
    isLogin(state, data) {
      state.login = data;
    },
    getadminlist(state, data) {
      state.adminlist = data;
    },
    // 设置修改套餐 state
    SetSet_meal(state, { term, start_time, end_time, pay_money, pay_type }) {
      state.set_meal = {
        term,
        start_time,
        end_time,
        pay_money,
        pay_type,
      };
    },
  },

  actions: {
    // adiminlist({ commit }, data) {
    //   commit("adiminlist", data);
    // },
  },
  getters: {
    adminlist: (state) => state.adminlist,
  },
  modules: {},
});
