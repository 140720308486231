<template>
  <div id="app">
    <!-- <router-view v-if="Logintoken"></router-view>
    <el-container style="height: 100vh" v-else>
      <el-header style="background-color: #333a4d; padding: 0; height: 80px">
        <hander-com></hander-com>
      </el-header>
      <el-container>
        <el-aside
          width="220px"
          style="background-color: #333a4d; overflow: hidden"
        >
          <mune-com></mune-com>
        </el-aside>
        <el-main style="padding: 0">
          <transition name="fade">
            <router-view></router-view>
          </transition>
          
        </el-main>
      </el-container>
    </el-container> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  //===========================下面是解决刷新页面丢失vuex数据
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },

  data() {
    return {

    }
  },
  watch: {
    // $route(val) {
    //   console.log(val)
    //   if (this.$route.path == "/admin") {
    //     this.Logintoken = true;
    //   } else {
    //     this.Logintoken = false;
    //   }
    // }
  }
}
</script>

<style lang="scss">
@import "assets/iconfont/iconfont.css";
@import "assets/css/index.scss";
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoraction: none;
}
.router-link-active {
  text-decoration: none;
}
.el-asude {
  overflow: hidden;
}
#app {
  font-family: PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
